"use strict";
exports.__esModule = true;
exports.Locale = void 0;
/** All locales with the dashes and capitalization, e.g. `en-US`, `zh-Hant-HK`. */
var Locale;
(function (Locale) {
    Locale["af"] = "af";
    Locale["af_NA"] = "af-NA";
    Locale["af_ZA"] = "af-ZA";
    Locale["agq"] = "agq";
    Locale["agq_CM"] = "agq-CM";
    Locale["ak"] = "ak";
    Locale["ak_GH"] = "ak-GH";
    Locale["am"] = "am";
    Locale["am_ET"] = "am-ET";
    Locale["ar"] = "ar";
    Locale["ar_001"] = "ar-001";
    Locale["ar_AE"] = "ar-AE";
    Locale["ar_BH"] = "ar-BH";
    Locale["ar_DZ"] = "ar-DZ";
    Locale["ar_EG"] = "ar-EG";
    Locale["ar_IQ"] = "ar-IQ";
    Locale["ar_JO"] = "ar-JO";
    Locale["ar_KW"] = "ar-KW";
    Locale["ar_LB"] = "ar-LB";
    Locale["ar_LY"] = "ar-LY";
    Locale["ar_MA"] = "ar-MA";
    Locale["ar_OM"] = "ar-OM";
    Locale["ar_QA"] = "ar-QA";
    Locale["ar_SA"] = "ar-SA";
    Locale["ar_SD"] = "ar-SD";
    Locale["ar_SY"] = "ar-SY";
    Locale["ar_TN"] = "ar-TN";
    Locale["ar_YE"] = "ar-YE";
    Locale["as"] = "as";
    Locale["as_IN"] = "as-IN";
    Locale["asa"] = "asa";
    Locale["asa_TZ"] = "asa-TZ";
    Locale["az"] = "az";
    Locale["az_Cyrl"] = "az-Cyrl";
    Locale["az_Cyrl_AZ"] = "az-Cyrl-AZ";
    Locale["az_Latn"] = "az-Latn";
    Locale["az_Latn_AZ"] = "az-Latn-AZ";
    Locale["bas"] = "bas";
    Locale["bas_CM"] = "bas-CM";
    Locale["be"] = "be";
    Locale["be_BY"] = "be-BY";
    Locale["bem"] = "bem";
    Locale["bem_ZM"] = "bem-ZM";
    Locale["bez"] = "bez";
    Locale["bez_TZ"] = "bez-TZ";
    Locale["bg"] = "bg";
    Locale["bg_BG"] = "bg-BG";
    Locale["bm"] = "bm";
    Locale["bm_ML"] = "bm-ML";
    Locale["bn"] = "bn";
    Locale["bn_BD"] = "bn-BD";
    Locale["bn_IN"] = "bn-IN";
    Locale["bo"] = "bo";
    Locale["bo_CN"] = "bo-CN";
    Locale["bo_IN"] = "bo-IN";
    Locale["br"] = "br";
    Locale["br_FR"] = "br-FR";
    Locale["brx"] = "brx";
    Locale["brx_IN"] = "brx-IN";
    Locale["bs"] = "bs";
    Locale["bs_BA"] = "bs-BA";
    Locale["ca"] = "ca";
    Locale["ca_ES"] = "ca-ES";
    Locale["cgg"] = "cgg";
    Locale["cgg_UG"] = "cgg-UG";
    Locale["chr"] = "chr";
    Locale["chr_US"] = "chr-US";
    Locale["cs"] = "cs";
    Locale["cs_CZ"] = "cs-CZ";
    Locale["cy"] = "cy";
    Locale["cy_GB"] = "cy-GB";
    Locale["da"] = "da";
    Locale["da_DK"] = "da-DK";
    Locale["dav"] = "dav";
    Locale["dav_KE"] = "dav-KE";
    Locale["de"] = "de";
    Locale["de_AT"] = "de-AT";
    Locale["de_BE"] = "de-BE";
    Locale["de_CH"] = "de-CH";
    Locale["de_DE"] = "de-DE";
    Locale["de_LI"] = "de-LI";
    Locale["de_LU"] = "de-LU";
    Locale["dje"] = "dje";
    Locale["dje_NE"] = "dje-NE";
    Locale["dua"] = "dua";
    Locale["dua_CM"] = "dua-CM";
    Locale["dyo"] = "dyo";
    Locale["dyo_SN"] = "dyo-SN";
    Locale["ebu"] = "ebu";
    Locale["ebu_KE"] = "ebu-KE";
    Locale["ee"] = "ee";
    Locale["ee_GH"] = "ee-GH";
    Locale["ee_TG"] = "ee-TG";
    Locale["el"] = "el";
    Locale["el_CY"] = "el-CY";
    Locale["el_GR"] = "el-GR";
    Locale["en"] = "en";
    Locale["en_AE"] = "en-AE";
    Locale["en_AR"] = "en-AR";
    Locale["en_AS"] = "en-AS";
    Locale["en_AT"] = "en-AT";
    Locale["en_AU"] = "en-AU";
    Locale["en_BB"] = "en-BB";
    Locale["en_BE"] = "en-BE";
    Locale["en_BG"] = "en-BG";
    Locale["en_BH"] = "en-BH";
    Locale["en_BM"] = "en-BM";
    Locale["en_BR"] = "en-BR";
    Locale["en_BW"] = "en-BW";
    Locale["en_BZ"] = "en-BZ";
    Locale["en_CA"] = "en-CA";
    Locale["en_CH"] = "en-CH";
    Locale["en_CL"] = "en-CL";
    Locale["en_CN"] = "en-CN";
    Locale["en_CO"] = "en-CO";
    Locale["en_CR"] = "en-CR";
    Locale["en_CY"] = "en-CY";
    Locale["en_CZ"] = "en-CZ";
    Locale["en_DE"] = "en-DE";
    Locale["en_DK"] = "en-DK";
    Locale["en_DO"] = "en-DO";
    Locale["en_EE"] = "en-EE";
    Locale["en_EG"] = "en-EG";
    Locale["en_ES"] = "en-ES";
    Locale["en_FI"] = "en-FI";
    Locale["en_GB"] = "en-GB";
    Locale["en_GE"] = "en-GE";
    Locale["en_GF"] = "en-GF";
    Locale["en_GH"] = "en-GH";
    Locale["en_GI"] = "en-GI";
    Locale["en_GR"] = "en-GR";
    Locale["en_GU"] = "en-GU";
    Locale["en_GY"] = "en-GY";
    Locale["en_HK"] = "en-HK";
    Locale["en_HR"] = "en-HR";
    Locale["en_HU"] = "en-HU";
    Locale["en_ID"] = "en-ID";
    Locale["en_IE"] = "en-IE";
    Locale["en_IL"] = "en-IL";
    Locale["en_IN"] = "en-IN";
    Locale["en_IS"] = "en-IS";
    Locale["en_IT"] = "en-IT";
    Locale["en_JM"] = "en-JM";
    Locale["en_JO"] = "en-JO";
    Locale["en_JP"] = "en-JP";
    Locale["en_KR"] = "en-KR";
    Locale["en_KW"] = "en-KW";
    Locale["en_KY"] = "en-KY";
    Locale["en_LB"] = "en-LB";
    Locale["en_LI"] = "en-LI";
    Locale["en_LT"] = "en-LT";
    Locale["en_LU"] = "en-LU";
    Locale["en_LV"] = "en-LV";
    Locale["en_MA"] = "en-MA";
    Locale["en_MC"] = "en-MC";
    Locale["en_MG"] = "en-MG";
    Locale["en_MH"] = "en-MH";
    Locale["en_MK"] = "en-MK";
    Locale["en_MO"] = "en-MO";
    Locale["en_MP"] = "en-MP";
    Locale["en_MT"] = "en-MT";
    Locale["en_MU"] = "en-MU";
    Locale["en_MX"] = "en-MX";
    Locale["en_MY"] = "en-MY";
    Locale["en_NA"] = "en-NA";
    Locale["en_NL"] = "en-NL";
    Locale["en_NO"] = "en-NO";
    Locale["en_NZ"] = "en-NZ";
    Locale["en_OM"] = "en-OM";
    Locale["en_PE"] = "en-PE";
    Locale["en_PH"] = "en-PH";
    Locale["en_PK"] = "en-PK";
    Locale["en_PL"] = "en-PL";
    Locale["en_PR"] = "en-PR";
    Locale["en_PT"] = "en-PT";
    Locale["en_PY"] = "en-PY";
    Locale["en_QA"] = "en-QA";
    Locale["en_RO"] = "en-RO";
    Locale["en_RU"] = "en-RU";
    Locale["en_SA"] = "en-SA";
    Locale["en_SE"] = "en-SE";
    Locale["en_SG"] = "en-SG";
    Locale["en_SK"] = "en-SK";
    Locale["en_SI"] = "en-SI";
    Locale["en_TH"] = "en-TH";
    Locale["en_TR"] = "en-TR";
    Locale["en_TT"] = "en-TT";
    Locale["en_TW"] = "en-TW";
    Locale["en_UA"] = "en-UA";
    Locale["en_UM"] = "en-UM";
    Locale["en_US"] = "en-US";
    Locale["en_US_POSIX"] = "en-US-POSIX";
    Locale["en_UY"] = "en-UY";
    Locale["en_VE"] = "en-VE";
    Locale["en_VI"] = "en-VI";
    Locale["en_VN"] = "en-VN";
    Locale["en_ZA"] = "en-ZA";
    Locale["en_ZW"] = "en-ZW";
    Locale["eo"] = "eo";
    Locale["es"] = "es";
    Locale["es_419"] = "es-419";
    Locale["es_AR"] = "es-AR";
    Locale["es_BO"] = "es-BO";
    Locale["es_CL"] = "es-CL";
    Locale["es_CO"] = "es-CO";
    Locale["es_CR"] = "es-CR";
    Locale["es_DO"] = "es-DO";
    Locale["es_EC"] = "es-EC";
    Locale["es_ES"] = "es-ES";
    Locale["es_GQ"] = "es-GQ";
    Locale["es_GT"] = "es-GT";
    Locale["es_HN"] = "es-HN";
    Locale["es_MX"] = "es-MX";
    Locale["es_NI"] = "es-NI";
    Locale["es_PA"] = "es-PA";
    Locale["es_PE"] = "es-PE";
    Locale["es_PR"] = "es-PR";
    Locale["es_PY"] = "es-PY";
    Locale["es_SV"] = "es-SV";
    Locale["es_US"] = "es-US";
    Locale["es_UY"] = "es-UY";
    Locale["es_VE"] = "es-VE";
    Locale["et"] = "et";
    Locale["et_EE"] = "et-EE";
    Locale["eu"] = "eu";
    Locale["eu_ES"] = "eu-ES";
    Locale["ewo"] = "ewo";
    Locale["ewo_CM"] = "ewo-CM";
    Locale["fa"] = "fa";
    Locale["fa_AF"] = "fa-AF";
    Locale["fa_IR"] = "fa-IR";
    Locale["ff"] = "ff";
    Locale["ff_SN"] = "ff-SN";
    Locale["fi"] = "fi";
    Locale["fi_FI"] = "fi-FI";
    Locale["fil"] = "fil";
    Locale["fil_PH"] = "fil-PH";
    Locale["fo"] = "fo";
    Locale["fo_FO"] = "fo-FO";
    Locale["fr"] = "fr";
    Locale["fr_BE"] = "fr-BE";
    Locale["fr_BF"] = "fr-BF";
    Locale["fr_BI"] = "fr-BI";
    Locale["fr_BJ"] = "fr-BJ";
    Locale["fr_BL"] = "fr-BL";
    Locale["fr_CA"] = "fr-CA";
    Locale["fr_CD"] = "fr-CD";
    Locale["fr_CF"] = "fr-CF";
    Locale["fr_CG"] = "fr-CG";
    Locale["fr_CH"] = "fr-CH";
    Locale["fr_CI"] = "fr-CI";
    Locale["fr_CM"] = "fr-CM";
    Locale["fr_DJ"] = "fr-DJ";
    Locale["fr_FR"] = "fr-FR";
    Locale["fr_GA"] = "fr-GA";
    Locale["fr_GF"] = "fr-GF";
    Locale["fr_GN"] = "fr-GN";
    Locale["fr_GP"] = "fr-GP";
    Locale["fr_GQ"] = "fr-GQ";
    Locale["fr_KM"] = "fr-KM";
    Locale["fr_LU"] = "fr-LU";
    Locale["fr_MC"] = "fr-MC";
    Locale["fr_MF"] = "fr-MF";
    Locale["fr_MG"] = "fr-MG";
    Locale["fr_ML"] = "fr-ML";
    Locale["fr_MQ"] = "fr-MQ";
    Locale["fr_NE"] = "fr-NE";
    Locale["fr_RE"] = "fr-RE";
    Locale["fr_RW"] = "fr-RW";
    Locale["fr_SN"] = "fr-SN";
    Locale["fr_TD"] = "fr-TD";
    Locale["fr_TG"] = "fr-TG";
    Locale["fr_YT"] = "fr-YT";
    Locale["ga"] = "ga";
    Locale["ga_IE"] = "ga-IE";
    Locale["gl"] = "gl";
    Locale["gl_ES"] = "gl-ES";
    Locale["gsw"] = "gsw";
    Locale["gsw_CH"] = "gsw-CH";
    Locale["gu"] = "gu";
    Locale["gu_IN"] = "gu-IN";
    Locale["guz"] = "guz";
    Locale["guz_KE"] = "guz-KE";
    Locale["gv"] = "gv";
    Locale["gv_GB"] = "gv-GB";
    Locale["ha"] = "ha";
    Locale["ha_Latn"] = "ha-Latn";
    Locale["ha_Latn_GH"] = "ha-Latn-GH";
    Locale["ha_Latn_NE"] = "ha-Latn-NE";
    Locale["ha_Latn_NG"] = "ha-Latn-NG";
    Locale["haw"] = "haw";
    Locale["haw_US"] = "haw-US";
    Locale["he"] = "he";
    Locale["he_IL"] = "he-IL";
    Locale["hi"] = "hi";
    Locale["hi_IN"] = "hi-IN";
    Locale["hr"] = "hr";
    Locale["hr_HR"] = "hr-HR";
    Locale["hu"] = "hu";
    Locale["hu_HU"] = "hu-HU";
    Locale["hy"] = "hy";
    Locale["hy_AM"] = "hy-AM";
    Locale["id"] = "id";
    Locale["id_ID"] = "id-ID";
    Locale["ig"] = "ig";
    Locale["ig_NG"] = "ig-NG";
    Locale["ii"] = "ii";
    Locale["ii_CN"] = "ii-CN";
    Locale["is"] = "is";
    Locale["is_IS"] = "is-IS";
    Locale["it"] = "it";
    Locale["it_CH"] = "it-CH";
    Locale["it_IT"] = "it-IT";
    Locale["ja"] = "ja";
    Locale["ja_JP"] = "ja-JP";
    Locale["jmc"] = "jmc";
    Locale["jmc_TZ"] = "jmc-TZ";
    Locale["ka"] = "ka";
    Locale["ka_GE"] = "ka-GE";
    Locale["kab"] = "kab";
    Locale["kab_DZ"] = "kab-DZ";
    Locale["kam"] = "kam";
    Locale["kam_KE"] = "kam-KE";
    Locale["kde"] = "kde";
    Locale["kde_TZ"] = "kde-TZ";
    Locale["kea"] = "kea";
    Locale["kea_CV"] = "kea-CV";
    Locale["khq"] = "khq";
    Locale["khq_ML"] = "khq-ML";
    Locale["ki"] = "ki";
    Locale["ki_KE"] = "ki-KE";
    Locale["kk"] = "kk";
    Locale["kk_Cyrl"] = "kk-Cyrl";
    Locale["kk_Cyrl_KZ"] = "kk-Cyrl-KZ";
    Locale["kl"] = "kl";
    Locale["kl_GL"] = "kl-GL";
    Locale["kln"] = "kln";
    Locale["kln_KE"] = "kln-KE";
    Locale["km"] = "km";
    Locale["km_KH"] = "km-KH";
    Locale["kn"] = "kn";
    Locale["kn_IN"] = "kn-IN";
    Locale["ko"] = "ko";
    Locale["ko_KR"] = "ko-KR";
    Locale["kok"] = "kok";
    Locale["kok_IN"] = "kok-IN";
    Locale["ksb"] = "ksb";
    Locale["ksb_TZ"] = "ksb-TZ";
    Locale["ksf"] = "ksf";
    Locale["ksf_CM"] = "ksf-CM";
    Locale["kw"] = "kw";
    Locale["kw_GB"] = "kw-GB";
    Locale["lag"] = "lag";
    Locale["lag_TZ"] = "lag-TZ";
    Locale["lg"] = "lg";
    Locale["lg_UG"] = "lg-UG";
    Locale["ln"] = "ln";
    Locale["ln_CD"] = "ln-CD";
    Locale["ln_CG"] = "ln-CG";
    Locale["lt"] = "lt";
    Locale["lt_LT"] = "lt-LT";
    Locale["lu"] = "lu";
    Locale["lu_CD"] = "lu-CD";
    Locale["luo"] = "luo";
    Locale["luo_KE"] = "luo-KE";
    Locale["luy"] = "luy";
    Locale["luy_KE"] = "luy-KE";
    Locale["lv"] = "lv";
    Locale["lv_LV"] = "lv-LV";
    Locale["mas"] = "mas";
    Locale["mas_KE"] = "mas-KE";
    Locale["mas_TZ"] = "mas-TZ";
    Locale["mer"] = "mer";
    Locale["mer_KE"] = "mer-KE";
    Locale["mfe"] = "mfe";
    Locale["mfe_MU"] = "mfe-MU";
    Locale["mg"] = "mg";
    Locale["mg_MG"] = "mg-MG";
    Locale["mgh"] = "mgh";
    Locale["mgh_MZ"] = "mgh-MZ";
    Locale["mk"] = "mk";
    Locale["mk_MK"] = "mk-MK";
    Locale["ml"] = "ml";
    Locale["ml_IN"] = "ml-IN";
    Locale["mr"] = "mr";
    Locale["mr_IN"] = "mr-IN";
    Locale["ms"] = "ms";
    Locale["ms_BN"] = "ms-BN";
    Locale["ms_MY"] = "ms-MY";
    Locale["mt"] = "mt";
    Locale["mt_MT"] = "mt-MT";
    Locale["mua"] = "mua";
    Locale["mua_CM"] = "mua-CM";
    Locale["my"] = "my";
    Locale["my_MM"] = "my-MM";
    Locale["naq"] = "naq";
    Locale["naq_NA"] = "naq-NA";
    Locale["nb"] = "nb";
    Locale["nb_NO"] = "nb-NO";
    Locale["nd"] = "nd";
    Locale["nd_ZW"] = "nd-ZW";
    Locale["ne"] = "ne";
    Locale["ne_IN"] = "ne-IN";
    Locale["ne_NP"] = "ne-NP";
    Locale["nl"] = "nl";
    Locale["nl_AW"] = "nl-AW";
    Locale["nl_BE"] = "nl-BE";
    Locale["nl_CW"] = "nl-CW";
    Locale["nl_NL"] = "nl-NL";
    Locale["nl_SX"] = "nl-SX";
    Locale["nmg"] = "nmg";
    Locale["nmg_CM"] = "nmg-CM";
    Locale["nn"] = "nn";
    Locale["nn_NO"] = "nn-NO";
    Locale["nus"] = "nus";
    Locale["nus_SD"] = "nus-SD";
    Locale["nyn"] = "nyn";
    Locale["nyn_UG"] = "nyn-UG";
    Locale["om"] = "om";
    Locale["om_ET"] = "om-ET";
    Locale["om_KE"] = "om-KE";
    Locale["or"] = "or";
    Locale["or_IN"] = "or-IN";
    Locale["pa"] = "pa";
    Locale["pa_Arab"] = "pa-Arab";
    Locale["pa_Arab_PK"] = "pa-Arab-PK";
    Locale["pa_Guru"] = "pa-Guru";
    Locale["pa_Guru_IN"] = "pa-Guru-IN";
    Locale["pl"] = "pl";
    Locale["pl_PL"] = "pl-PL";
    Locale["ps"] = "ps";
    Locale["ps_AF"] = "ps-AF";
    Locale["pt"] = "pt";
    Locale["pt_AO"] = "pt-AO";
    Locale["pt_BR"] = "pt-BR";
    Locale["pt_GW"] = "pt-GW";
    Locale["pt_MZ"] = "pt-MZ";
    Locale["pt_PT"] = "pt-PT";
    Locale["pt_ST"] = "pt-ST";
    Locale["rm"] = "rm";
    Locale["rm_CH"] = "rm-CH";
    Locale["rn"] = "rn";
    Locale["rn_BI"] = "rn-BI";
    Locale["ro"] = "ro";
    Locale["ro_MD"] = "ro-MD";
    Locale["ro_RO"] = "ro-RO";
    Locale["rof"] = "rof";
    Locale["rof_TZ"] = "rof-TZ";
    Locale["ru"] = "ru";
    Locale["ru_MD"] = "ru-MD";
    Locale["ru_RU"] = "ru-RU";
    Locale["ru_UA"] = "ru-UA";
    Locale["rw"] = "rw";
    Locale["rw_RW"] = "rw-RW";
    Locale["rwk"] = "rwk";
    Locale["rwk_TZ"] = "rwk-TZ";
    Locale["saq"] = "saq";
    Locale["saq_KE"] = "saq-KE";
    Locale["sbp"] = "sbp";
    Locale["sbp_TZ"] = "sbp-TZ";
    Locale["seh"] = "seh";
    Locale["seh_MZ"] = "seh-MZ";
    Locale["ses"] = "ses";
    Locale["ses_ML"] = "ses-ML";
    Locale["sg"] = "sg";
    Locale["sg_CF"] = "sg-CF";
    Locale["shi"] = "shi";
    Locale["shi_Latn"] = "shi-Latn";
    Locale["shi_Latn_MA"] = "shi-Latn-MA";
    Locale["shi_Tfng"] = "shi-Tfng";
    Locale["shi_Tfng_MA"] = "shi-Tfng-MA";
    Locale["si"] = "si";
    Locale["si_LK"] = "si-LK";
    Locale["sk"] = "sk";
    Locale["sk_SK"] = "sk-SK";
    Locale["sl"] = "sl";
    Locale["sl_SI"] = "sl-SI";
    Locale["sn"] = "sn";
    Locale["sn_ZW"] = "sn-ZW";
    Locale["so"] = "so";
    Locale["so_DJ"] = "so-DJ";
    Locale["so_ET"] = "so-ET";
    Locale["so_KE"] = "so-KE";
    Locale["so_SO"] = "so-SO";
    Locale["sq"] = "sq";
    Locale["sq_AL"] = "sq-AL";
    Locale["sr"] = "sr";
    Locale["sr_Cyrl"] = "sr-Cyrl";
    Locale["sr_Cyrl_BA"] = "sr-Cyrl-BA";
    Locale["sr_Cyrl_ME"] = "sr-Cyrl-ME";
    Locale["sr_Cyrl_RS"] = "sr-Cyrl-RS";
    Locale["sr_Latn"] = "sr-Latn";
    Locale["sr_Latn_BA"] = "sr-Latn-BA";
    Locale["sr_Latn_ME"] = "sr-Latn-ME";
    Locale["sr_Latn_RS"] = "sr-Latn-RS";
    Locale["sv"] = "sv";
    Locale["sv_FI"] = "sv-FI";
    Locale["sv_SE"] = "sv-SE";
    Locale["sw"] = "sw";
    Locale["sw_KE"] = "sw-KE";
    Locale["sw_TZ"] = "sw-TZ";
    Locale["swc"] = "swc";
    Locale["swc_CD"] = "swc-CD";
    Locale["ta"] = "ta";
    Locale["ta_IN"] = "ta-IN";
    Locale["ta_LK"] = "ta-LK";
    Locale["te"] = "te";
    Locale["te_IN"] = "te-IN";
    Locale["teo"] = "teo";
    Locale["teo_KE"] = "teo-KE";
    Locale["teo_UG"] = "teo-UG";
    Locale["th"] = "th";
    Locale["th_TH"] = "th-TH";
    Locale["ti"] = "ti";
    Locale["ti_ER"] = "ti-ER";
    Locale["ti_ET"] = "ti-ET";
    Locale["to"] = "to";
    Locale["to_TO"] = "to-TO";
    Locale["tr"] = "tr";
    Locale["tr_TR"] = "tr-TR";
    Locale["twq"] = "twq";
    Locale["twq_NE"] = "twq-NE";
    Locale["tzm"] = "tzm";
    Locale["tzm_Latn"] = "tzm-Latn";
    Locale["tzm_Latn_MA"] = "tzm-Latn-MA";
    Locale["uk"] = "uk";
    Locale["uk_UA"] = "uk-UA";
    Locale["ur"] = "ur";
    Locale["ur_IN"] = "ur-IN";
    Locale["ur_PK"] = "ur-PK";
    Locale["uz"] = "uz";
    Locale["uz_Arab"] = "uz-Arab";
    Locale["uz_Arab_AF"] = "uz-Arab-AF";
    Locale["uz_Cyrl"] = "uz-Cyrl";
    Locale["uz_Cyrl_UZ"] = "uz-Cyrl-UZ";
    Locale["uz_Latn"] = "uz-Latn";
    Locale["uz_Latn_UZ"] = "uz-Latn-UZ";
    Locale["vai"] = "vai";
    Locale["vai_Latn"] = "vai-Latn";
    Locale["vai_Latn_LR"] = "vai-Latn-LR";
    Locale["vai_Vaii"] = "vai-Vaii";
    Locale["vai_Vaii_LR"] = "vai-Vaii-LR";
    Locale["vi"] = "vi";
    Locale["vi_VN"] = "vi-VN";
    Locale["vun"] = "vun";
    Locale["vun_TZ"] = "vun-TZ";
    Locale["xog"] = "xog";
    Locale["xog_UG"] = "xog-UG";
    Locale["yav"] = "yav";
    Locale["yav_CM"] = "yav-CM";
    Locale["yo"] = "yo";
    Locale["yo_NG"] = "yo-NG";
    Locale["zh"] = "zh";
    Locale["zh_Hans"] = "zh-Hans";
    Locale["zh_Hans_AE"] = "zh-Hans-AE";
    Locale["zh_Hans_AR"] = "zh-Hans-AR";
    Locale["zh_Hans_AT"] = "zh-Hans-AT";
    Locale["zh_Hans_AU"] = "zh-Hans-AU";
    Locale["zh_Hans_BE"] = "zh-Hans-BE";
    Locale["zh_Hans_BG"] = "zh-Hans-BG";
    Locale["zh_Hans_BH"] = "zh-Hans-BH";
    Locale["zh_Hans_BR"] = "zh-Hans-BR";
    Locale["zh_Hans_BW"] = "zh-Hans-BW";
    Locale["zh_Hans_CA"] = "zh-Hans-CA";
    Locale["zh_Hans_CH"] = "zh-Hans-CH";
    Locale["zh_Hans_CL"] = "zh-Hans-CL";
    Locale["zh_Hans_CN"] = "zh-Hans-CN";
    Locale["zh_Hans_CO"] = "zh-Hans-CO";
    Locale["zh_Hans_CR"] = "zh-Hans-CR";
    Locale["zh_Hans_CY"] = "zh-Hans-CY";
    Locale["zh_Hans_CZ"] = "zh-Hans-CZ";
    Locale["zh_Hans_DE"] = "zh-Hans-DE";
    Locale["zh_Hans_DK"] = "zh-Hans-DK";
    Locale["zh_Hans_DO"] = "zh-Hans-DO";
    Locale["zh_Hans_EE"] = "zh-Hans-EE";
    Locale["zh_Hans_EG"] = "zh-Hans-EG";
    Locale["zh_Hans_ES"] = "zh-Hans-ES";
    Locale["zh_Hans_FI"] = "zh-Hans-FI";
    Locale["zh_Hans_GB"] = "zh-Hans-GB";
    Locale["zh_Hans_GE"] = "zh-Hans-GE";
    Locale["zh_Hans_GF"] = "zh-Hans-GF";
    Locale["zh_Hans_GH"] = "zh-Hans-GH";
    Locale["zh_Hans_GI"] = "zh-Hans-GI";
    Locale["zh_Hans_GR"] = "zh-Hans-GR";
    Locale["zh_Hans_HK"] = "zh-Hans-HK";
    Locale["zh_Hans_HR"] = "zh-Hans-HR";
    Locale["zh_Hans_HU"] = "zh-Hans-HU";
    Locale["zh_Hans_ID"] = "zh-Hans-ID";
    Locale["zh_Hans_IE"] = "zh-Hans-IE";
    Locale["zh_Hans_IL"] = "zh-Hans-IL";
    Locale["zh_Hans_IN"] = "zh-Hans-IN";
    Locale["zh_Hans_IS"] = "zh-Hans-IS";
    Locale["zh_Hans_IT"] = "zh-Hans-IT";
    Locale["zh_Hans_JO"] = "zh-Hans-JO";
    Locale["zh_Hans_JP"] = "zh-Hans-JP";
    Locale["zh_Hans_KR"] = "zh-Hans-KR";
    Locale["zh_Hans_KW"] = "zh-Hans-KW";
    Locale["zh_Hans_KY"] = "zh-Hans-KY";
    Locale["zh_Hans_LB"] = "zh-Hans-LB";
    Locale["zh_Hans_LI"] = "zh-Hans-LI";
    Locale["zh_Hans_LT"] = "zh-Hans-LT";
    Locale["zh_Hans_LU"] = "zh-Hans-LU";
    Locale["zh_Hans_LV"] = "zh-Hans-LV";
    Locale["zh_Hans_MA"] = "zh-Hans-MA";
    Locale["zh_Hans_MC"] = "zh-Hans-MC";
    Locale["zh_Hans_MG"] = "zh-Hans-MG";
    Locale["zh_Hans_MK"] = "zh-Hans-MK";
    Locale["zh_Hans_MO"] = "zh-Hans-MO";
    Locale["zh_Hans_MT"] = "zh-Hans-MT";
    Locale["zh_Hans_MU"] = "zh-Hans-MU";
    Locale["zh_Hans_MX"] = "zh-Hans-MX";
    Locale["zh_Hans_MY"] = "zh-Hans-MY";
    Locale["zh_Hans_NA"] = "zh-Hans-NA";
    Locale["zh_Hans_NL"] = "zh-Hans-NL";
    Locale["zh_Hans_NO"] = "zh-Hans-NO";
    Locale["zh_Hans_NZ"] = "zh-Hans-NZ";
    Locale["zh_Hans_OM"] = "zh-Hans-OM";
    Locale["zh_Hans_PE"] = "zh-Hans-PE";
    Locale["zh_Hans_PH"] = "zh-Hans-PH";
    Locale["zh_Hans_PK"] = "zh-Hans-PK";
    Locale["zh_Hans_PL"] = "zh-Hans-PL";
    Locale["zh_Hans_PR"] = "zh-Hans-PR";
    Locale["zh_Hans_PT"] = "zh-Hans-PT";
    Locale["zh_Hans_PY"] = "zh-Hans-PY";
    Locale["zh_Hans_QA"] = "zh-Hans-QA";
    Locale["zh_Hans_RO"] = "zh-Hans-RO";
    Locale["zh_Hans_RU"] = "zh-Hans-RU";
    Locale["zh_Hans_SA"] = "zh-Hans-SA";
    Locale["zh_Hans_SE"] = "zh-Hans-SE";
    Locale["zh_Hans_SG"] = "zh-Hans-SG";
    Locale["zh_Hans_SK"] = "zh-Hans-SK";
    Locale["zh_Hans_SI"] = "zh-Hans-SI";
    Locale["zh_Hans_TH"] = "zh-Hans-TH";
    Locale["zh_Hans_TR"] = "zh-Hans-TR";
    Locale["zh_Hans_TW"] = "zh-Hans-TW";
    Locale["zh_Hans_UA"] = "zh-Hans-UA";
    Locale["zh_Hans_US"] = "zh-Hans-US";
    Locale["zh_Hans_UY"] = "zh-Hans-UY";
    Locale["zh_Hans_VE"] = "zh-Hans-VE";
    Locale["zh_Hans_VN"] = "zh-Hans-VN";
    Locale["zh_Hans_ZA"] = "zh-Hans-ZA";
    Locale["zh_Hant"] = "zh-Hant";
    Locale["zh_Hant_AE"] = "zh-Hant-AE";
    Locale["zh_Hant_AR"] = "zh-Hant-AR";
    Locale["zh_Hant_AT"] = "zh-Hant-AT";
    Locale["zh_Hant_AU"] = "zh-Hant-AU";
    Locale["zh_Hant_BE"] = "zh-Hant-BE";
    Locale["zh_Hant_BG"] = "zh-Hant-BG";
    Locale["zh_Hant_BH"] = "zh-Hant-BH";
    Locale["zh_Hant_BR"] = "zh-Hant-BR";
    Locale["zh_Hant_BW"] = "zh-Hant-BW";
    Locale["zh_Hant_CA"] = "zh-Hant-CA";
    Locale["zh_Hant_CH"] = "zh-Hant-CH";
    Locale["zh_Hant_CL"] = "zh-Hant-CL";
    Locale["zh_Hant_CN"] = "zh-Hant-CN";
    Locale["zh_Hant_CO"] = "zh-Hant-CO";
    Locale["zh_Hant_CR"] = "zh-Hant-CR";
    Locale["zh_Hant_CY"] = "zh-Hant-CY";
    Locale["zh_Hant_CZ"] = "zh-Hant-CZ";
    Locale["zh_Hant_DE"] = "zh-Hant-DE";
    Locale["zh_Hant_DK"] = "zh-Hant-DK";
    Locale["zh_Hant_DO"] = "zh-Hant-DO";
    Locale["zh_Hant_EE"] = "zh-Hant-EE";
    Locale["zh_Hant_EG"] = "zh-Hant-EG";
    Locale["zh_Hant_ES"] = "zh-Hant-ES";
    Locale["zh_Hant_FI"] = "zh-Hant-FI";
    Locale["zh_Hant_GB"] = "zh-Hant-GB";
    Locale["zh_Hant_GE"] = "zh-Hant-GE";
    Locale["zh_Hant_GF"] = "zh-Hant-GF";
    Locale["zh_Hant_GH"] = "zh-Hant-GH";
    Locale["zh_Hant_GI"] = "zh-Hant-GI";
    Locale["zh_Hant_GR"] = "zh-Hant-GR";
    Locale["zh_Hant_HK"] = "zh-Hant-HK";
    Locale["zh_Hant_HR"] = "zh-Hant-HR";
    Locale["zh_Hant_HU"] = "zh-Hant-HU";
    Locale["zh_Hant_ID"] = "zh-Hant-ID";
    Locale["zh_Hant_IE"] = "zh-Hant-IE";
    Locale["zh_Hant_IL"] = "zh-Hant-IL";
    Locale["zh_Hant_IN"] = "zh-Hant-IN";
    Locale["zh_Hant_IS"] = "zh-Hant-IS";
    Locale["zh_Hant_IT"] = "zh-Hant-IT";
    Locale["zh_Hant_JO"] = "zh-Hant-JO";
    Locale["zh_Hant_JP"] = "zh-Hant-JP";
    Locale["zh_Hant_KR"] = "zh-Hant-KR";
    Locale["zh_Hant_KW"] = "zh-Hant-KW";
    Locale["zh_Hant_KY"] = "zh-Hant-KY";
    Locale["zh_Hant_LB"] = "zh-Hant-LB";
    Locale["zh_Hant_LI"] = "zh-Hant-LI";
    Locale["zh_Hant_LT"] = "zh-Hant-LT";
    Locale["zh_Hant_LU"] = "zh-Hant-LU";
    Locale["zh_Hant_LV"] = "zh-Hant-LV";
    Locale["zh_Hant_MA"] = "zh-Hant-MA";
    Locale["zh_Hant_MC"] = "zh-Hant-MC";
    Locale["zh_Hant_MG"] = "zh-Hant-MG";
    Locale["zh_Hant_MK"] = "zh-Hant-MK";
    Locale["zh_Hant_MO"] = "zh-Hant-MO";
    Locale["zh_Hant_MT"] = "zh-Hant-MT";
    Locale["zh_Hant_MU"] = "zh-Hant-MU";
    Locale["zh_Hant_MX"] = "zh-Hant-MX";
    Locale["zh_Hant_MY"] = "zh-Hant-MY";
    Locale["zh_Hant_NA"] = "zh-Hant-NA";
    Locale["zh_Hant_NL"] = "zh-Hant-NL";
    Locale["zh_Hant_NO"] = "zh-Hant-NO";
    Locale["zh_Hant_NZ"] = "zh-Hant-NZ";
    Locale["zh_Hant_OM"] = "zh-Hant-OM";
    Locale["zh_Hant_PE"] = "zh-Hant-PE";
    Locale["zh_Hant_PH"] = "zh-Hant-PH";
    Locale["zh_Hant_PK"] = "zh-Hant-PK";
    Locale["zh_Hant_PL"] = "zh-Hant-PL";
    Locale["zh_Hant_PR"] = "zh-Hant-PR";
    Locale["zh_Hant_PT"] = "zh-Hant-PT";
    Locale["zh_Hant_PY"] = "zh-Hant-PY";
    Locale["zh_Hant_QA"] = "zh-Hant-QA";
    Locale["zh_Hant_RO"] = "zh-Hant-RO";
    Locale["zh_Hant_RU"] = "zh-Hant-RU";
    Locale["zh_Hant_SA"] = "zh-Hant-SA";
    Locale["zh_Hant_SE"] = "zh-Hant-SE";
    Locale["zh_Hant_SG"] = "zh-Hant-SG";
    Locale["zh_Hant_SK"] = "zh-Hant-SK";
    Locale["zh_Hant_SI"] = "zh-Hant-SI";
    Locale["zh_Hant_TH"] = "zh-Hant-TH";
    Locale["zh_Hant_TR"] = "zh-Hant-TR";
    Locale["zh_Hant_TW"] = "zh-Hant-TW";
    Locale["zh_Hant_UA"] = "zh-Hant-UA";
    Locale["zh_Hant_US"] = "zh-Hant-US";
    Locale["zh_Hant_UY"] = "zh-Hant-UY";
    Locale["zh_Hant_VE"] = "zh-Hant-VE";
    Locale["zh_Hant_VN"] = "zh-Hant-VN";
    Locale["zh_Hant_ZA"] = "zh-Hant-ZA";
    Locale["zu"] = "zu";
    Locale["zu_ZA"] = "zu-ZA";
})(Locale = exports.Locale || (exports.Locale = {}));
;
