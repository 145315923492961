import memoize from 'lodash.memoize';
function resolver(options) {
    return JSON.stringify(options);
}
function uniqDefined(arr) {
    return arr.filter(function (el, index) { return el && arr.indexOf(el) === index; });
}
function isAllLowerCase(el) {
    return el.toLowerCase() === el;
}
function normalizeLocale(el) {
    if (!el || el.indexOf('-') === -1 || !isAllLowerCase(el)) {
        return el;
    }
    var _a = el.split('-'), _b = _a[0], splitEl1 = _b === void 0 ? '' : _b, _c = _a[1], splitEl2 = _c === void 0 ? '' : _c;
    return "".concat(splitEl1, "-").concat(splitEl2.toUpperCase());
}
function getUserLocalesInternal(_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.useFallbackLocale, useFallbackLocale = _c === void 0 ? true : _c, _d = _b.fallbackLocale, fallbackLocale = _d === void 0 ? 'en-US' : _d;
    var languageList = [];
    if (typeof navigator !== 'undefined') {
        languageList = languageList.concat(navigator.languages, navigator.language);
    }
    if (useFallbackLocale) {
        languageList.push(fallbackLocale);
    }
    return uniqDefined(languageList).map(normalizeLocale);
}
export var getUserLocales = memoize(getUserLocalesInternal, resolver);
function getUserLocaleInternal(options) {
    return getUserLocales(options)[0] || null;
}
export var getUserLocale = memoize(getUserLocaleInternal, resolver);
export default getUserLocale;
